import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
      }}
    >
      <h1
        style={{ textAlign: "center", marginBottom: "20px", color: "#007BFF" }}
      >
        Terms and Conditions
      </h1>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Accuracy of Information Provided
      </h2>

      <h3 style={{ color: "#0056b3" }}>Commitment to Accuracy</h3>
      <p>
        By becoming a member, you hereby affirm that all information provided by
        you during the membership application process and throughout the
        duration of your membership is true, accurate, and complete to the best
        of your knowledge.
      </p>

      <h3 style={{ color: "#0056b3" }}>Verification of Information</h3>
      <p>
        We reserve the right to verify any and all information provided by you
        at any time. This verification may include, but is not limited to,
        contacting third parties, conducting background checks, or requesting
        additional documentation from you.
      </p>

      <h3 style={{ color: "#0056b3" }}>
        Consequences of Providing Incorrect Information
      </h3>
      <p>
        If any information provided by you is found to be false, misleading, or
        incorrect upon verification, your membership may be immediately
        disqualified. In such cases, you will be notified of the
        disqualification and the reasons for it.
      </p>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Compliance with Indian Law
      </h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of India. Any disputes arising from or related to the
        enforcement of these terms and conditions will be subject to the
        exclusive jurisdiction of the courts in India.
      </p>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Acknowledgment and Acceptance
      </h2>
      <p>
        By submitting your membership application, you acknowledge that you have
        read, understood, and agreed to these terms and conditions. You further
        acknowledge that providing false or misleading information is a serious
        offense and that you accept the consequences outlined herein.
      </p>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Amendments
      </h2>
      <p>
        We reserve the right to amend these terms and conditions at any time
        without prior notice. Any changes will be effective immediately upon
        posting on our website. It is your responsibility to review these terms
        and conditions periodically to stay informed of any updates.
      </p>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Contact Information
      </h2>
      <p>
        If you have any questions or concerns regarding these terms and
        conditions, please contact us at:
        <br />
        <a href="mailto:manage.pdsi@gmail.com" style={{ color: "#007BFF" }}>
          manage.pdsi@gmail.com
        </a>
      </p>

      <h2 style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px" }}>
        Acceptance of Terms
      </h2>
      <p>
        By proceeding with the membership application, you hereby accept and
        agree to abide by these terms and conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
