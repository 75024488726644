import React, { useState, useEffect } from "react";

// Tab Button Component
const Tab = ({ children, active, onClick }) => (
  <button
    className={`px-4 py-3 font-medium text-sm focus:outline-none transition-colors duration-300 ease-in-out 
               ${
                 active
                   ? "bg-white text-blue-600 border-b-2 border-blue-600 shadow-sm"
                   : "text-gray-600 hover:text-blue-500 hover:bg-gray-50"
               }`}
    onClick={onClick}
  >
    {children}
  </button>
);

// Tab Content Wrapper Component
const TabContent = ({ children, isActive }) => (
  <div
    className={`transition-opacity duration-300 ease-in-out ${
      isActive ? "opacity-100" : "opacity-0 hidden"
    }`}
  >
    <div className="p-6 bg-white rounded-b-lg shadow-md">{children}</div>
  </div>
);

// Main Tab Component with Responsive Design
const TabComponent = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="bg-gray-100 border-b border-gray-200">
      <nav className="flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`px-4 py-3 font-medium text-sm focus:outline-none transition-colors duration-300 ease-in-out ${
              activeTab === index
                ? "bg-white text-blue-600 border-b-2 border-blue-600 shadow-sm"
                : "text-gray-600 hover:text-blue-500 hover:bg-gray-50"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default TabComponent;
