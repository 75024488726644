import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const PaymentProofUpload = ({ getMemberDataFromServer }) => {
  const [{ user }] = useAtom(gbState);
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { files } = e.target;
    setFile(files[0]);
  };

  const handleFileUpload = () => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("transaction_receipt", file);
    let userToken = localStorage.getItem("token");
    let url = `${process.env.REACT_APP_API_URL}/user/upload/tr/${user?._id}`;

    axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res?.data?.message);
        getMemberDataFromServer();
        console.log(res?.data);
      })
      .catch((err) => {
        toast.error(
          err.response?.data
            ? JSON.stringify(err.response.data)
            : "An error occurred"
        );
        console.log(err);
      });
  };

  return (
    <div className="max-w-4xl p-6 mx-auto mt-4 bg-white border border-gray-200 rounded-lg shadow-lg">
      <div className="flex flex-col w-full md:flex-row">
        <div className="flex items-center justify-center w-full mt-3 text-center md:mt-0 md:w-2/3">
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="transaction_receipt"
          >
            Transaction Receipt
          </label>
          <input
            type="file"
            id="transaction_receipt"
            name="transaction_receipt"
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-center w-full mt-3 text-center md:mt-0 md:w-1/3">
          <button
            className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
            onClick={handleFileUpload}
          >
            Upload Receipt
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentProofUpload;
