import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import UserView from "./UserView";
import DocumentApproval from "./DocumentApproval";
import UserRegistration from "./UserRegistration";
import UpdateUserRegistration from "./UpdateUserRegistration";
import { render } from "@testing-library/react";
import PendingRegistrationModal from "./PendingRegistrationModal";
import UserProfile from "./UserProfile";

const CurrentApplicationChild = ({ setNumPendingApplications }) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  //const [numPendingApplications, setNumPendingApplications] = useState(0);

  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };
  const toggleUpdateUserModal = () => {
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const toggleApprovalModal = () => {
    setShowApprovalModal(!showApprovalModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/delete/${row._id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "UsersListChild", "/users");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response?.data?.users;
        const filteredData = oldData?.filter((item) => item?.document_upload);
        // console.log(filteredData?.data?.document_status);
        const updatedData = filteredData?.map((data) => {
          return {
            ...data,
            id: data?._id,
            name: `${data?.role === "DOCTOR" ? "Dr. " : ""} ${
              data?.first_name
            } ${data?.last_name}`,
            email: data?.email,
            role: data?.role,
            applied_for: data?.membership_applied_for,
            document_upload: data?.document_upload,
            status: data?.user_type,
            subscription_type: data?.subscription_type,
            account_status: data?.account_status,
            document_status: data?.document_status,
            verification_status:
              data?.subscription_type === "Free" && data?.transaction_receipt
                ? "Pending"
                : data?.transaction_receipt
                ? "Approved"
                : "N/A",
          };
        });
        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const filteredData = data?.filter(
        (item) => item?.document_status === "Pending"
      );
      setNumPendingApplications(filteredData?.length);
    }
  }, [data]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip> */}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 190,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <span
              className={`mr-2 w-2.5 h-2.5 rounded-full ${
                params.row.account_status ? "bg-green-500" : "bg-red-500"
              }`}
            ></span>
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "applied_for",
      headerName: "Applied For",
      width: 180,
      renderCell: (params) => {
        return (
          params.row.applied_for && JSON?.parse(params.row.applied_for)?.label
        );
      },
    },
    {
      field: "educational_qualification",
      headerName: "Qualification",
      width: 180,
      renderCell: (params) => {
        return (
          params.row.educational_qualification &&
          JSON.parse(params.row.educational_qualification)?.label
        );
      },
    },
    {
      field: "document_upload",
      headerName: "Document",
      width: 190,
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-center w-full h-full">
            <a
              href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${params.row.document_upload}`}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Download
            </a>
          </div>
        );
      },
    },
    {
      field: "document_status",
      headerName: "Document Status",
      width: 190,
      renderCell: (params) => {
        let statusColor = "";
        let statusText = "";

        switch (params.row.document_status) {
          case "Approved":
            statusColor = "bg-green-100 text-green-800";
            statusText = "Approved";
            break;
          case "Rejected":
            statusColor = "bg-red-100 text-red-800";
            statusText = "Rejected";
            break;
          default:
            statusColor = "bg-orange-100 text-orange-800";
            statusText = "Pending";
        }

        return (
          <div className="flex items-center justify-center w-full h-full">
            <span
              className={`px-2 py-1 text-xs font-medium rounded-full ${statusColor}`}
            >
              {statusText}
            </span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 190,
    },
    {
      field: "user_type",
      headerName: "Role",
      width: 140,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 140,
    },
    {
      field: "subscription_type",
      headerName: "Subscription",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return params.row.subscription_type === "Free" ? (
          <span className="inline-block w-full px-2 py-1 text-xs text-center text-gray-800 bg-red-300 rounded ">
            {params.row.subscription_type}
          </span>
        ) : (
          <span className="inline-block w-full px-2 py-1 text-xs text-center text-gray-800 bg-green-300 rounded ">
            {params.row.subscription_type}
          </span>
        );
      },
    },

    // {
    //   field: "account_status",
    //   headerName: "Status",
    //   sortable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   width: 140,
    //   renderCell: (params) => {
    //     return params.row.account_status ? (
    //       <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded ">
    //         ACTIVE
    //       </span>
    //     ) : (
    //       <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded ">
    //         DISABLED
    //       </span>
    //     );
    //   },
    // },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateUserModal(true);
  };

  const handleApprovalButton = (row) => {
    setCurrentRecord(row);
    setShowApprovalModal(true);
  };

  return (
    <div className="w-full mt-4" style={{ wordBreak: "break-word" }}>
      {/* <div className="flex justify-end w-full">
        <button
          className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          onClick={toggleAddUserModal}
        >
          Current Applications
        </button>
      </div> */}
      <h2> Current Applications</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <UserProfile userData={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddUserModal && (
          <Modal onClose={toggleAddUserModal}>
            <h2 className="mb-4 text-xl font-bold">Add User</h2>
            <UserRegistration
              setShowModal={setShowAddUserModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes> */}
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateUserModal && (
          <Modal onClose={toggleUpdateUserModal}>
            <h2 className="mb-4 text-xl font-bold">Document Approval</h2>
            <DocumentApproval
              setShowModal={setShowUpdateUserModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showApprovalModal && (
          <Modal onClose={toggleApprovalModal}>
            <h2 className="mb-4 text-xl font-bold">Update User</h2>
            <PendingRegistrationModal
              setShowModal={setShowUpdateUserModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>

      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default CurrentApplicationChild;
