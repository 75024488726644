import React from "react";

const ViewRoles = ({ currentRecord }) => {
  return (
    <div className="max-w-2xl mx-auto mt-10 p-5 border rounded-lg shadow-lg bg-white">
      {/* <h3 className="text-2xl font-bold mb-6 text-center text-gray-800">
        View Roles
      </h3> */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Role Name
        </label>
        <p className="p-2 border rounded bg-gray-100">
          {currentRecord.role_name}
        </p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Access Level
        </label>
        <p className="p-2 border rounded bg-gray-100">
          {currentRecord.access_level}
        </p>
      </div>
    </div>
  );
};

export default ViewRoles;
