import React, { useState } from "react";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import UploadEducationalInformation from "./UploadEducationalInformation";

const UploadEducationalCertificateChild = () => {
  //   const [{ user }] = useAtom(gbState);
  return (
    <div className="w-full py-10">
      <UploadEducationalInformation />
    </div>
  );
};

export default UploadEducationalCertificateChild;
