import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";
import Login from "../../pages/Login/Login";
import AdminDashboard from "../../pages/AdminDashboard/AdminDashboard";
import MemberDashboard from "../../pages/MemberDashboard/MemberDashboard";
import DoctorProfile from "../../pages/DoctorProfile/DoctorProfile";
import ChangePassword from "../../pages/ChangePassword/ChangePassword";
import MemberAccount from "../../pages/MemberAccount/MemberAccount";
import Documentation from "../../pages/Documentation/Documentation";
import ManageCertificates from "../../pages/ManageCertificates/ManageCertificates";
import ManageTemplates from "../../pages/ManageCertificates/ManageTemplates";
import ManageRoles from "../../pages/Roles/ManageRoles";
import Unauthorised from "../../pages/404/Unauthorised";
import Page404 from "../../pages/404/Page404";
import Page403 from "../../pages/403/Page403";
import Subscription from "../../pages/Subscription/Subscription";
import UploadEducationalCertificate from "../../pages/MemberDashboard/UploadEducationalCertificate";
import CurrentApplications from "../../pages/AdminDashboard/CurrentApplications";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" name="Login" element={<Login />} />
      <Route path="/login" name="Login" element={<Login />} />
      <Route
        path="/error404"
        name="Error: Page not found"
        element={<Unauthorised />}
      />
      <Route element={<PrivateRoutes />}>
        <Route
          path="/error404"
          name="Error: Page not found"
          element={<Page404 />}
        />
        <Route
          path="/error403"
          name="Error: Permission denied"
          element={<Page403 />}
        />
        <Route element={<PrivateRoutes roleRequired={["ADMIN"]} />}>
          <Route
            path="/dashboard-admin"
            name="Admin Dashboard"
            element={<AdminDashboard />}
          />
          <Route
            path="/templates"
            name="Templates"
            element={<ManageTemplates />}
          />
          <Route path="/roles" name="Roles" element={<ManageRoles />} />
          <Route
            path="/applications"
            name="Current Applications"
            element={<CurrentApplications />}
          />
        </Route>
        <Route
          element={
            <PrivateRoutes
              roleRequired={["LIFETIME", "ADMIN", "ASSOCIATE", "NOT_A_MEMBER"]}
            />
          }
        >
          {/* <Route
            path="/dashboard-doctor"
            name="Member Dashboard"
            element={<MemberDashboard />}
          /> */}
          <Route
            path="/dashboard-member"
            name="Member Dashboard"
            element={<MemberDashboard />}
          />
          <Route
            path="/profile"
            name="Member Profile"
            element={<DoctorProfile />}
          />
          <Route
            path="/cpass"
            name="Member Change Password"
            element={<ChangePassword />}
          />
          <Route
            path="/account"
            name="Member Account"
            element={<MemberAccount />}
          />
          <Route
            path="/documents"
            name="Documentation"
            element={<Documentation />}
          />
          <Route
            path="/certificates"
            name="Certificates"
            element={<ManageCertificates />}
          />
          <Route
            path="/subscription"
            name="Subscription"
            element={<Subscription />}
          />
          <Route
            path="/upload-certificates"
            name="Upload Certificates"
            element={<UploadEducationalCertificate />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
