import React from "react";

const UserView = ({ user }) => {
  return (
    <div className="max-w-4xl p-5 mx-auto mt-10 border rounded-lg shadow-lg">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.first_name}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.last_name}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Mobile
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.mobile}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.email}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.role}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            User Type
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.user_type}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Organization
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.organization}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Account Status
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {user?.account_status ? "Active" : "Inactive"}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Subscription Type
          </label>
          <p
            className={`p-2 ${
              user?.subscription_type === "Free" ? "bg-red-300" : "bg-green-300"
            }  border rounded`}
          >
            {user?.subscription_type}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Certificate Status
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {user?.certificate_status ? "Active" : "Inactive"}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Gender
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.gender}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Designation
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.designation}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Created At
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {new Date(user?.createdAt)?.toLocaleString()}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Updated At
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {new Date(user?.updatedAt)?.toLocaleString()}
          </p>
        </div>
        {user?.document_upload && (
          <div className="col-span-1 mt-4 text-center md:col-span-2">
            <a
              href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${user.document_upload}`}
              target="_blank"
              download
              className="inline-block p-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Academic Document
            </a>
          </div>
        )}
        {user?.transaction_receipt && (
          <div className="col-span-1 mt-4 text-center md:col-span-2">
            <a
              href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${user.transaction_receipt}`}
              target="_blank"
              download
              className="inline-block p-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Download Transaction Receipt
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserView;
