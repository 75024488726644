import React from "react";

const RejectedApplication = () => {
  return (
    <div className="max-w-4xl mx-auto mt-8 overflow-hidden bg-white border border-red-200 rounded-lg shadow-lg">
      <div className="p-8 text-center">
        <div className="mb-6 text-red-500">
          <svg
            className="w-20 h-20 mx-auto"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="mb-6 text-3xl font-bold text-gray-800">
          Application Status
        </h2>
        <p className="mb-4 text-xl font-semibold text-red-600">
          Your application has been rejected.
        </p>
        <p className="text-lg text-gray-600">
          We regret to inform you that your application did not meet our current
          requirements. Please contact our support team for more information or
          to discuss your options.
        </p>
        <div className="mt-8">
          <button className="px-6 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectedApplication;
