import React, { useState, useRef, useEffect } from "react";
import ClientCaptcha from "react-client-captcha";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import {
  titleOptions,
  academicStatusOptions,
  qualificationOptions,
  membershipOptions,
} from "../../components/Data/dropdownoptions";

const MemberPublicRegistration = ({ setShowModal }) => {
  // Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [captchaWidth, setCaptchaWidth] = useState(250);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedMembershipType, setSelectedMembershipType] = useState();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  let captchaRef = useRef();

  const [formData, setFormData] = useState({
    member_title: null,
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    hospital_name: null,
    organization: null,
    password: null,
    confirm_password: null,
    academic_status: null,
    account_status: false,
    document_upload: null,
    membership_applied_for: null,
    educational_qualification: null,
    role: "NOT_A_MEMBER",
    user_type: "Not a Member",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleChangeFile = (e) => {
    const { files } = e.target;
    setFile(files[0]);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleResize = () => setCaptchaWidth(mediaQuery.matches ? 250 : 150);
    mediaQuery.addListener(handleResize);
    handleResize(); // Set the initial value
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();
    if (!formData.member_title) return toast.error("Please select you title!");

    if (!formData.first_name) return toast.error("Input your Name");
    if (!formData.last_name) return toast.error("Input your Last Name");
    if (!formData.email) return toast.error("Input your Email");
    if (!formData.academic_status)
      return toast.error("Error No Academic status selected");
    if (!formData.mobile) return toast.error("Input Mobile number");

    if (!formData.organization) return toast.error("Please input organization");

    if (!formData.membership_applied_for)
      return toast.error("Select Member type");

    if (!formData.educational_qualification)
      return toast.error("Select educational qualification");

    // Check Captcha
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to Submit, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (!formData.password) return toast.error("Input your Password");

    if (formData.password !== formData.confirm_password)
      return toast.error(`Passwords not Matching!`);

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    setLoading(true);

    const frmData = new FormData();
    frmData.append("document_upload", file);
    frmData.append("member_title", formData.member_title);
    frmData.append("first_name", formData.first_name);
    frmData.append("last_name", formData.last_name);
    frmData.append("email", formData.email);
    frmData.append("password", formData.password);
    frmData.append("academic_status", formData.academic_status);
    frmData.append("mobile", formData.mobile);
    frmData.append("organization", formData.organization);
    frmData.append("role", "NOT_A_MEMBER");
    frmData.append("user_type", "Not a Member");
    frmData.append("account_status", false);
    frmData.append("document_status", "Pending");
    frmData.append(
      "membership_applied_for",
      JSON.stringify(selectedMembershipType)
    );
    frmData.append(
      "educational_qualification",
      JSON.stringify(selectedQualification)
    );

    const urlRegister = `${process.env.REACT_APP_API_URL}/user/register`;

    axios
      .post(urlRegister, frmData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        toast.success("Registration Successful.");
        setShowModal(false);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(JSON.stringify(error.response.data));
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <form className="space-y-4" onSubmit={handleRegistrationSubmit}>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/6">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="member_title"
            >
              Title <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="member_title"
              options={titleOptions}
              styles={style}
              placeholder={placeHolderText("Title")}
              onChange={(selected) =>
                dropDownChange(selected, { name: "member_title" })
              }
              value={titleOptions.find(
                (option) => option.value === formData.member_title
              )}
              className="w-full"
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full md:w-2/5">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="first_name"
            >
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-2/5">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={formData.last_name && formData.last_name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="mobile">
              Mobile <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              value={formData.mobile && formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="organization"
            >
              Organization <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="organization"
              id="organization"
              value={formData.organization && formData.organization}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="email">
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email && formData.email}
              autoComplete="new-password"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="academic_status"
            >
              Academic Status <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="academic_status"
              options={academicStatusOptions}
              styles={style}
              placeholder={placeHolderText("Select Academics")}
              onChange={(selected) =>
                dropDownChange(selected, { name: "academic_status" })
              }
              value={academicStatusOptions.find(
                (option) => option.value === formData.academic_status
              )}
              className="w-full"
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label htmlFor="membership_applied_for">
              Membership Type <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="membership_applied_for"
              onChange={(selected) => {
                setSelectedMembershipType(selected);
                setFormData({
                  ...formData,
                  membership_applied_for: JSON.stringify(selected),
                });
              }}
              options={membershipOptions}
              className="w-full h-10 outline-none"
              placeholder={placeHolderText("Select Membership")}
              styles={style}
              value={selectedMembershipType}
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="educational_qualification">
              Educational Qualification <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="educational_qualification"
              onChange={(selected) => {
                setSelectedQualification(selected);
                setFormData({
                  ...formData,
                  educational_qualification: JSON.stringify(selected),
                });
              }}
              options={qualificationOptions}
              className="w-full h-10 outline-none"
              placeholder={placeHolderText("Select Qualification")}
              styles={style}
              value={selectedQualification}
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-100">
            <label htmlFor="document_upload">
              Upload supporting documents{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="file"
              id="document_upload"
              name="document_upload"
              accept=".pdf,.jpg,.jpeg,.png,.gif"
              onChange={handleChangeFile}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="password"
            >
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              autoComplete="new-password"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="confirm_password"
            >
              Confirm Password <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <ClientCaptcha
              captchaCode={setCode}
              width={captchaWidth}
              className="rounded-md"
            />
          </div>
          <div className="w-full mt-4 md:w-1/2 md:mt-0">
            <input
              type="text"
              placeholder="Enter Captcha"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              onChange={(e) => setState({ ...state, valid: e.target.value })}
              name="valid"
              ref={captchaRef}
              onKeyPress={(e) =>
                e.key === "Enter" && handleRegistrationSubmit(e)
              }
              autoComplete="off"
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-2 text-white rounded bg-gradient-to-r from-navy-500 to-sky-500 hover:from-sky-500 hover:to-navy-500"
          disabled={loading}
        >
          {loading ? "Submitting..." : "APPLY"}
        </button>
      </form>
      <div className="mt-4 text-center">
        <p className="text-sm">
          Already have an account?{" "}
          <span
            onClick={() => setShowModal(false)}
            className="cursor-pointer text-navy-500 hover:underline"
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default MemberPublicRegistration;
