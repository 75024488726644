import React, { useEffect, useState } from "react";
import TopMenu from "../TopMenu/TopMenu";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

// import CardBackground from "../../pages/Dashboard/CardBackground";

const MainTemplate = ({ children }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [menuState, setMenuState] = useState(true);
  const inputWidth = "230px";

  const adminRoles = ["CHURCH_ADMIN"];
  return (
    <div className="flex flex-col bg-gray-100 h-screen">
      <TopMenu menuState={menuState} setMenuState={setMenuState} />
      <div class="flex-grow bg-gray-100">{children}</div>
    </div>
  );
};

export default MainTemplate;
