import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import ClientCaptcha from "react-client-captcha";

import Modal from "../../components/Modal/Modal";
import logo_image from "../../images/pdsi-logo-transparent-2x.png";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState, globalUserStatus } from "../../components/shared/GlobalState";
import MemberPublicRegistration from "./MemberPublicRegistration";
import ForgotPassword from "./ForgotPassword";
import VerifyCertificateModal from "./VerifyCertificateModal";

const Login = () => {
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [userGlobal, setUserGlobal] = useAtom(globalUserStatus);
  const [showModal, setShowModal] = useState(false);
  const [showModalForgotPass, setShowModalForgotPass] = useState(false);
  const [verifyCertificateModal, setVerifyCertificateModal] = useState(false);

  const [loggedUser, setLoggedUser] = useState(null);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModalForgotPass = () => {
    setShowModalForgotPass(!showModalForgotPass);
  };

  const toggleVerifyCertificate = () => {
    setVerifyCertificateModal(!verifyCertificateModal);
  };

  let usernameRef = useRef();
  let passwordRef = useRef();
  let captchaRef = useRef();

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const checkLogin = async (email, password) => {
    const credentials = { email, password };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials
    );

    return response;
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("user-details");
        setUserStatus({ ...userStatus, user: null });
        navigate("/login", { replace: true });
      }
    }
  };

  const userRoles = ["LIFETIME", "ASSOCIATE", "NOT_A_MEMBER"];

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (usernameRef.current.value && passwordRef.current.value) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        localStorage.setItem("token", response.data.authToken);

        setInterval(checkToken, 1000 * 60 * 10);
        const user = jwt_decode(response.data.authToken);
        setLoggedUser(jwt_decode(response.data.authToken));

        setUserStatus({
          ...userStatus,
          user: jwt_decode(response.data.authToken),
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            navigate("/login", { replace: true });
          },
        });

        setUserGlobal({ ...jwt_decode(response.data.authToken) });

        if (user.role === "ADMIN") {
          navigate("/dashboard-admin", { replace: true });
        } else if (user.role === "NOT_A_MEMBER") {
          navigate("/dashboard-member", { replace: true });
        } else if (userRoles.includes(user.role)) {
          navigate("/dashboard-member", { replace: true });
        }

        toast.success("Logged in successfully");
      } catch (error) {
        toast.error("Unable to login, Bad username or password");
      }
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleMediaChange = (e) => setMatches(e.matches);

    mediaQuery.addEventListener("change", handleMediaChange);

    return () => mediaQuery.removeEventListener("change", handleMediaChange);
  }, []);

  return (
    <div className="flex flex-col min-h-screen md:flex-row">
      <div className="flex flex-col items-center justify-center w-full p-8 bg-white md:w-1/2 text-navy-500">
        <div className="w-full max-w-md">
          <div className="mb-8 text-center">
            <img src={logo_image} alt="Logo" className="mx-auto mb-4" />
          </div>
          {/* Forgot Modal */}
          {showModalForgotPass && (
            <Modal onClose={toggleModalForgotPass} size="md">
              <ForgotPassword setShowModalForgotPass={setShowModalForgotPass} />
            </Modal>
          )}
          {/* Registration Modal */}
          {showModal && (
            <Modal onClose={toggleModal}>
              <h2 className="mb-4 text-xl font-bold">Registration</h2>
              <MemberPublicRegistration setShowModal={setShowModal} />
            </Modal>
          )}
          {/* Verify Certificate modal */}
          {verifyCertificateModal && (
            <Modal onClose={toggleVerifyCertificate}>
              <VerifyCertificateModal
                setVerifyCertificateModal={setVerifyCertificateModal}
              />
            </Modal>
          )}
          <form className="space-y-4">
            <div>
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="username"
              >
                Username
              </label>
              <input
                type="email"
                id="emailaddress"
                required
                placeholder="Enter your email"
                autoComplete="new-password"
                ref={usernameRef}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              />
            </div>
            <div>
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                required
                placeholder="Password"
                autoComplete="new-password"
                ref={passwordRef}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              />
            </div>
            <div className="block mb-1 text-sm font-medium">
              <ClientCaptcha
                captchaCode={setCode}
                width={matches ? 380 : 250}
                className="rounded-md"
              />
            </div>
            <div className="mt-3">
              <input
                type="text"
                placeholder="Enter Captcha"
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                onChange={(e) => setState({ ...state, valid: e.target.value })}
                name="valid"
                ref={captchaRef}
                onKeyPress={(e) => e.key === "Enter" && handleLoginSubmit(e)}
                autoComplete="off"
              />
            </div>
            <button
              onClick={(e) => handleLoginSubmit(e)}
              className="w-full py-2 text-white rounded bg-gradient-to-r from-sky-500 to-navy-500 hover:from-navy-600 hover:to-sky-600"
            >
              LOG IN
            </button>
          </form>
          <div className="md:flex md:flex-row md:justify-between">
            <div className="mt-4 text-center">
              <a
                onClick={toggleModalForgotPass}
                className="text-sm cursor-pointer text-navy-500 hover:underline"
              >
                Forgot password?
              </a>
            </div>
            <div className="mt-4 text-center">
              <p className="text-sm">
                Don't have an account?{" "}
                <span
                  onClick={toggleModal}
                  className="cursor-pointer text-navy-500 hover:underline"
                >
                  REGISTER
                </span>
              </p>
            </div>
          </div>
          <div className="mt-5 text-center">
            <span
              onClick={toggleVerifyCertificate}
              className="cursor-pointer text-navy-500 hover:underline hover:text-bold"
            >
              {" "}
              <p className="text-sm">Verify your Certificate</p>
            </span>
          </div>
          <div className="mt-5 text-center">
            <p className="text-[0.5rem]">Build: v20240710001.1</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full p-8 text-white md:w-1/2 bg-gradient-to-b from-navy-700 to-sky-500">
        <div className="max-w-md">
          <h2 className="mb-4 text-3xl font-bold">
            Welcome to Peritoneal Dialysis Society of India
          </h2>
          <p className="text-justify">
            Peritoneal dialysis, a highly effective and globally recognized
            dialysis modality, stands on par with hemodialysis in terms of
            efficacy. In fact, several countries have adopted a “PD first”
            approach, prioritizing peritoneal dialysis for its significant
            benefits. This method is especially advantageous for a specific
            subset of patients who experience enhanced outcomes from peritoneal
            dialysis...
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
