export const titleOptions = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Prof.", label: "Prof." },
];

export const academicStatusOptions = [
  { value: "Nephrologist", label: "Nephrologist" },
  { value: "Nephrology Fellow", label: "Nephrology Fellow" },
  { value: "Nephrology Resident", label: "Nephrology Resident" },
  { value: "Other doctors", label: "Other doctors" },
  { value: "Technician/Nurses", label: "Technician/Nurses" },
  { value: "Other", label: "Other" },
];

export const qualificationOptions = [
  { value: "bachelors", label: "Bachelor's Degree" },
  { value: "masters", label: "Master's Degree" },
  { value: "doctoral", label: "Doctorate/Doctoral Degree" },
];

export const membershipOptions = [
  { value: "LIFETIME", label: "Lifetime Membership" },
  { value: "ASSOCIATE", label: "Associate Membership" },
];
