import React, { useState, useEffect } from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import AdminDashboardChild from "./AdminDashboardChild";
import CurrentApplicationChild from "./CurrentApplicationChild";
import axios from "axios";

import TabComponent from "../../components/MainTemplate/TabComponent";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [numPendingApplications, setNumPendingApplications] = useState(0);

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response?.data?.users;
        const filteredData1 = oldData?.filter((item) => item?.document_upload);
        const filteredData = filteredData1?.filter(
          (item) => item?.document_status === "Pending"
        );
        setNumPendingApplications(filteredData?.length);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data?.message);
      });
  }, []);

  const tabs = [
    {
      label: "Admin Dashboard",
      component: AdminDashboardChild,
    },
    {
      label: `Pending Document Approvals (${numPendingApplications}) `,
      component: CurrentApplicationChild,
    },
  ];

  const renderTabContent = () => {
    if (tabs[activeTab].component) {
      const Component = tabs[activeTab].component;
      return (
        <Component setNumPendingApplications={setNumPendingApplications} />
      );
    }
    return null; // Default case if no component is associated with a tab
  };

  return (
    <MainTemplate>
      <CardBackground>
        <div className="w-full">
          <TabComponent
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {renderTabContent()}
        </div>
      </CardBackground>
    </MainTemplate>
  );
};

export default AdminDashboard;
