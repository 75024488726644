import { atom } from "jotai";
import {
  sanitizeFunction,
  errorLogFunction,
  encryptData,
  decryptData,
  dateFormatter,
  placeHolderText,
  bloodgroupOptions,
} from "./GeneralStateFunctions";

/*
To import in any component in header

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

inside function
//Error Logging Service & Santize Input
const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

*/
export const gbState = atom({
  errorLogApi: errorLogFunction,
  sanitizeInput: sanitizeFunction,
  encryptData: encryptData,
  decryptData: decryptData,
  dateFormatter: dateFormatter,
  placeHolderText: placeHolderText,
  bloodgroupOptions: bloodgroupOptions,
});

export const fMember = atom([]);

export const globalUserStatus = atom([]);

export const postState = atom();

export const fYear = atom({});
export const gbMessageState = atom(false);
