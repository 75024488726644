import React from "react";

const UserView = ({ user }) => {
  return (
    <div className="max-w-4xl p-5 mx-auto border rounded-lg shadow-lg">
      {/* <h2 className="mb-6 text-2xl font-bold text-center">User Details</h2> */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.first_name}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.last_name}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Mobile
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.mobile}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.email}</p>
        </div>
        {/* <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.role}</p>
        </div> */}
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Member Type
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.user_type}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Organization
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.organization}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Account Status
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {user?.account_status ? "Active" : "Inactive"}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Subcription Type{" "}
          </label>
          <p
            className={`p-2 ${
              user?.subscription_type === "Free" ? "bg-red-300" : "bg-green-300"
            }  border rounded`}
          >
            {user?.subscription_type}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Certificate Status
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {user?.certificate_status ? "Active" : "Inactive"}
          </p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Gender
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.gender}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Designation
          </label>
          <p className="p-2 bg-gray-100 border rounded">{user?.designation}</p>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Created At
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {new Date(user?.createdAt)?.toLocaleString()}
          </p>
        </div>

        {/* <div className="col-span-1 ">
          <label className="block text-sm font-medium text-gray-700">
            Updated At
          </label>
          <p className="p-2 bg-gray-100 border rounded">
            {new Date(user?.updatedAt)?.toLocaleString()}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default UserView;
