import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import useFetch from "../../components/shared/useFetch";

const AddTemplate = ({ getDataFromServer }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [roleOptions, setRoleOptions] = useState();

  const [formData, setFormData] = useState({
    template_name: "",
    template_background: null,
    active_status: "false",
    role: null,
    user_type: null,
  });

  const role_url = `${process.env.REACT_APP_API_URL}/roles/`;
  const { data, loading, error } = useFetch(role_url);

  useEffect(() => {
    if (data) {
      setRoleOptions(
        data?.roles?.map((role) => ({
          value: role.access_level,
          label: role.role_name,
        }))
      );
      // console.log(data);
    }
  }, [data]);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const activeStatusOptions = [
    { value: "0", label: "InActive" },
    { value: "1", label: "Active" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      template_background: e.target.files[0],
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.template_name)
      return toast.error("Template Name is required!");

    if (!formData.role) return toast.error("Select The Role type");

    if (!formData.template_background)
      return toast.error("Select the template background to upload");

    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/template/add`;

    const formPayload = new FormData();
    formPayload.append("template_name", formData.template_name);
    formPayload.append("active_status", formData.active_status);
    formPayload.append("template_background", formData.template_background);
    formPayload.append("role", formData?.role);
    formPayload.append("user_type", formData?.user_type);

    try {
      const response = await axios.post(urlLink, formPayload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
      });
      toast.success("Template added successfully!");
      setFormData({
        template_name: "",
        template_background: null,
        active_status: "false",
      });
      getDataFromServer();
    } catch (error) {
      toast.error("Failed to add template. Please try again.");
      console.error("There was an error adding the template:", error);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto mt-10">
      <form className="space-y-4">
        <div>
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="template_name"
          >
            Template Name
          </label>
          <input
            type="text"
            id="template_name"
            name="template_name"
            value={formData.template_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>

        <div>
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="template_background"
          >
            Template Background
          </label>
          <input
            type="file"
            id="template_background"
            name="template_background"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Member roles
            </label>
            <Select
              name="role"
              onChange={(selected, dropdown) =>
                setFormData({
                  ...formData,
                  role: selected.value,
                  user_type: selected.label,
                })
              }
              options={roleOptions}
              placeholder={placeHolderText("Select Status")}
              styles={style}
              value={
                formData?.role &&
                roleOptions?.find((option) => option.value === formData?.role)
              }
              menuPosition={"fixed"}
            />
          </div>
          &nbsp;
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="active_status"
            >
              Active Status
            </label>
            <Select
              name="active_status"
              onChange={dropDownChange}
              options={activeStatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Status")}
              styles={style}
              value={
                formData?.active_status &&
                activeStatusOptions?.find(
                  (option) => option.value === formData?.active_status
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>

        <div className="text-end">
          <button
            onClick={handleSubmit}
            className="p-3 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Add Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTemplate;
