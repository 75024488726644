import React from "react";

const UserProfile = ({ userData }) => {
  if (!userData) {
    return <div className="max-w-4xl p-6 mx-auto">Loading user data...</div>;
  }

  const excludeFields = [
    "__v",
    "_id",
    "id",
    "buddies",
    "hospital_name",
    "pendingBuddyRequests",
    "buddyRequests",
    "updated",
  ];
  const filteredData = Object.entries(userData).filter(
    ([key]) => !excludeFields.includes(key)
  );

  const formatKey = (key) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatValue = (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (value === null || value === undefined) return "N/A";
    if (typeof value === "object") return JSON.stringify(value);
    return value;
  };

  return (
    <div className="max-w-4xl p-6 mx-auto">
      <h2 className="mb-6 text-2xl font-bold">User Profile</h2>

      {/* Key-Value Row Layout */}
      <div className="mb-8 overflow-hidden bg-white rounded-lg shadow-md">
        {filteredData.map(([key, value]) => (
          <div key={key} className="flex border-b last:border-b-0">
            <div className="w-1/3 p-3 font-semibold bg-gray-100">
              {formatKey(key)}
            </div>
            <div className="w-2/3 p-3">{formatValue(value)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserProfile;
