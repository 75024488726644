import React from "react";
import SubscriptionInformation from "./SubscriptionInformation";
import PaymentProofUpload from "./PaymentProofUpload";

const SubscriptionChild = () => {
  return (
    <div className="w-full py-10">
      <SubscriptionInformation />
      <PaymentProofUpload />
    </div>
  );
};

export default SubscriptionChild;
