import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const Documentation = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <h1>Documentation</h1>
      </CardBackground>
    </MainTemplate>
  );
};

export default Documentation;
