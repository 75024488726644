import React, { useEffect, useState } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

import useFetch from "../../components/shared/useFetch";

const GenerateCertificateMember = ({ member, getMemberDataFromServer }) => {
  const [certficate, setCertificate] = useState({
    name_on_certificate: `${member?.member_title} ${member?.first_name} ${member?.last_name}`,
    template_id: "",
    template_background: null,
    active_status: "true",
    user_id: member?._id,
  });

  const [template, setTemplate] = useState();

  let geturltemplate = `${process.env.REACT_APP_API_URL}/template/template`;

  //using useFetch
  const { data, loading, error } = useFetch(geturltemplate);

  useEffect(() => {
    setTemplate(data);
  }, [data]);

  useEffect(() => {
    setCertificate({
      ...certficate,
      template_id: template?._id,
      template_background: template?.template_background,
    });
  }, [template]);

  const handleGenerateCertificate = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to generate the Certificate with the given name ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleGenCert(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const handleGenCert = (row) => {
      if (!certficate?.name_on_certificate) {
        return toast.error("Please enter name on certificate");
      }

      if (!certficate?.template_id) {
        return toast.error("Please select template");
      }

      if (!certficate?.user_id) {
        return toast.error("Please select Member");
      }

      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/certificate/add/`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: certficate,
      };

      axios(config)
        .then(function (response) {
          toast.success("Certificate Generated Successfully.");
          getMemberDataFromServer();
        })
        .catch(function (error) {
          toast.error(
            error.response?.data
              ? JSON.stringify(error.response.data)
              : "An error occurred"
          );
        });
    };
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setCertificate({
      ...certficate,
      [name]: value,
    });
  };

  return (
    <div className="max-w-4xl p-5 mx-auto mt-4 border rounded-lg shadow-lg md:mt-0">
      <div className="flex flex-col items-center md:flex-row md:space-x-4">
        <div className="w-full md:w-2/3">
          {/* input element */}
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="name_on_certificate"
          >
            Name on Certificate
          </label>
          <input
            type="text"
            id="name_on_certificate"
            name="name_on_certificate"
            value={certficate?.name_on_certificate}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-center w-full mt-4 text-center md:mt-6 md:w-1/3">
          <button
            className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
            onClick={() => handleGenerateCertificate()}
          >
            Generate Certificate
          </button>
        </div>
      </div>
      {/* {JSON.stringify(certficate)} */}
      <br />
    </div>
  );
};

export default GenerateCertificateMember;
