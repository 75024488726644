import React from "react";

const UploadEducationalInformation = () => {
  return (
    <div className="max-w-4xl p-5 mx-auto mt-4 mb-4 text-justify border rounded-lg shadow-lg md:mt-0">
      <h2 className="mb-4 text-2xl font-bold text-center text-gray-800">
        Membership Information
      </h2>
      <p className="mb-4 text-gray-700">
        The Society is dedicated to expanding the reach of peritoneal dialysis
        and nephrology by welcoming new members. The membership categories
        include Lifetime members, Associate members, and Honorary members. All
        members can participate in the Society's activities and will receive
        online access to the Indian Journal of Peritoneal Dialysis.
        Additionally, they are entitled to vote in the election of the office
        bearers.
      </p>
      <h3 className="mb-2 text-xl font-semibold text-gray-800">
        Eligibility for Lifetime Membership
      </h3>
      <ul className="mb-4 text-gray-700 list-disc list-inside">
        <li>
          Holding a DM/DNB or equivalent qualification in Nephrology from
          countries recognized by the Medical Council of India.
        </li>
        <li>
          Being a student registered for DM/DNB with at least one year of
          training.
        </li>
        <li>
          Physicians/Pediatricians with an MD/DNB and at least one year of
          training in Nephrology at a recognized hospital/institution.
        </li>
      </ul>
      <p className="mb-4 text-gray-700">
        Lifetime membership is available for a one-time fee of Rs 2000.
        Individuals who do not meet the above qualifications but work in
        Nephrology or related specialties, or are directly involved in dialysis
        or transplantation, may be admitted as Associate members. Associate
        members do not have voting rights for the election of office bearers.
        The admission fee for an Associate member is 50% of the fee for an
        ordinary member.
      </p>
      <p className="text-gray-700">
        The Governing Body can admit individuals of exceptional eminence who
        have significantly contributed to the Society's aims as Honorary
        members.
      </p>
    </div>
  );
};

export default UploadEducationalInformation;
