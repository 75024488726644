import React, { useState, useRef, useEffect } from "react";
import ClientCaptcha from "react-client-captcha";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const UpdateUserRegistration = ({ getDataFromServer, currentRecord }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [captchaWidth, setCaptchaWidth] = useState(250);
  const captchaRef = useRef();
  const [formData, setFormData] = useState({
    member_title: currentRecord?.member_title,
    first_name: currentRecord?.first_name,
    last_name: currentRecord?.last_name,
    mobile: currentRecord?.mobile,
    email: currentRecord?.email,
    hospital_name: currentRecord?.hospital_name,
    organization: currentRecord?.organization,
    password: "",
    confirm_password: "",
    account_status: currentRecord?.account_status,
    payment_status: currentRecord?.payment_status,
    subscription_type: currentRecord?.subscription_type,
    transaction_receipt: currentRecord?.transaction_receipt
      ? currentRecord?.transaction_receipt
      : null,
  });
  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleResize = () => setCaptchaWidth(mediaQuery.matches ? 250 : 150);
    mediaQuery.addListener(handleResize);
    handleResize(); // Set the initial value
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
  };

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const paymentStatusOptions = [
    { value: "0", label: "Not Paid" },
    { value: "1", label: "Paid" },
  ];
  const accountStatusOptions = [
    { value: false, label: "Disabled" },
    { value: true, label: "Enabled" },
  ];

  const subscriptionTypeOptions = [
    { value: "Free", label: "Free" },
    { value: "Paid", label: "Paid" },
  ];

  const titleOptions = [
    { value: "Dr.", label: "Dr." },
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Prof.", label: "Prof." },
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleRegistrationUpdate = (e) => {
    e.preventDefault();
    // Check Captcha
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (changePassword && formData.password !== formData.confirm_password) {
      return toast.error("Passwords not matching!");
    }

    // Create a new FormData object
    const formPayload = new FormData();

    // Append form data to FormData object
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    if (!changePassword) {
      formPayload.delete("password");
      formPayload.delete("confirm_password");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/admin/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: formPayload,
    };

    axios(config)
      .then(function (response) {
        toast.success("User Updated Successfully.");
        getDataFromServer();
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
      });
  };

  const documentStatusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  return (
    <div className="w-full">
      <form className="space-y-4" onSubmit={handleRegistrationUpdate}>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="member_title"
            >
              Title
            </label>
            <Select
              name="member_title"
              onChange={(selected) =>
                dropDownChange(selected, { name: "member_title" })
              }
              options={titleOptions}
              placeholder={placeHolderText("Select Title")}
              styles={style}
              value={
                formData?.member_title &&
                titleOptions.find(
                  (option) => option.value === formData?.member_title
                )
              }
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              value={formData.first_name}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="mobile">
              Mobile
            </label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="organization"
            >
              Organization
            </label>
            <input
              type="text"
              name="organization"
              id="organization"
              value={formData.organization}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Subscription Type
            </label>
            <Select
              name="subscription_type"
              onChange={(selected) =>
                dropDownChange(selected, { name: "subscription_type" })
              }
              options={subscriptionTypeOptions}
              placeholder={placeHolderText("Select Subscription Type")}
              styles={style}
              value={
                formData?.subscription_type &&
                subscriptionTypeOptions.find(
                  (option) => option.value === formData?.subscription_type
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <div className="items-center md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Account Status
            </label>
            <Select
              name="account_status"
              onChange={(selected) =>
                dropDownChange(selected, { name: "account_status" })
              }
              options={accountStatusOptions}
              placeholder={placeHolderText("Select Account Status")}
              styles={style}
              value={
                formData?.account_status &&
                accountStatusOptions.find(
                  (option) => option.value === formData?.account_status
                )
              }
              menuPosition={"fixed"}
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="transaction_receipt"
            >
              Transaction Receipt
            </label>
            <Select
              name="document_status"
              onChange={dropDownChange}
              options={documentStatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Document Status")}
              //   styles={style}
              value={
                formData?.document_status &&
                documentStatusOptions?.find(
                  (option) => option.value === formData?.document_status
                )
              }
              menuPosition={"fixed"}
            />
            {/* <input
              type="file"
              id="transaction_receipt"
              name="transaction_receipt"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            /> */}
          </div>
        </div>
        <div className="items-center md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="flex items-center mb-1 text-sm font-medium"
              htmlFor="password"
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={changePassword}
                onChange={() => setChangePassword(!changePassword)}
              />
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              autoComplete="false"
              onChange={handleChange}
              value={formData.password}
              disabled={!changePassword}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              disabled={!changePassword}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <ClientCaptcha
              captchaCode={setCode}
              width={captchaWidth}
              className="rounded-md"
            />
          </div>
          <div className="w-full mt-4 md:w-1/2 md:mt-0">
            <input
              type="text"
              placeholder="Enter Captcha"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              onChange={(e) => setState({ ...state, valid: e.target.value })}
              name="valid"
              ref={captchaRef}
              onKeyPress={(e) =>
                e.key === "Enter" && handleRegistrationUpdate(e)
              }
              autoComplete="off"
            />
          </div>
        </div>
        <div className="text-end">
          <button
            type="submit"
            className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUserRegistration;
