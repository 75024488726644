import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const ViewCertificate = ({ currentRecord }) => {
  const certificateRef = useRef();

  const handleDownloadPDF = () => {
    const input = certificateRef.current;
    html2canvas(input, { useCORS: true, logging: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", [
          canvas.width * 0.264583,
          canvas.height * 0.264583,
        ]);
        const pdfWidth = canvas.width * 0.264583;
        const pdfHeight = canvas.height * 0.264583;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${currentRecord?.certificate_code || "certificate"}.pdf`);
      })
      .catch((error) => {
        console.error("Could not generate PDF:", error);
      });
  };

  return (
    <div>
      <div ref={certificateRef} className="relative w-full mb-4">
        <img
          crossOrigin="anonymous"
          src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${currentRecord?.template_background}`}
          alt="Template Background"
          style={{ height: "100%" }}
          className="w-full p-0 mr-1"
        />
        <div
          className="absolute w-full text-center"
          style={{ top: "195px", left: "50%", transform: "translateX(-50%)" }}
        >
          <span className="text-lg font-bold">
            {currentRecord?.name_on_certificate}
          </span>
        </div>
        <div
          className="absolute w-full pr-10 text-right"
          style={{ top: "33px", left: "50%", transform: "translateX(-50%)" }}
        >
          <span className="text-sm font-normal">
            {currentRecord?.certificate_code}
          </span>
        </div>
      </div>
      <button
        onClick={handleDownloadPDF}
        className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Download PDF
      </button>
    </div>
  );
};

export default ViewCertificate;
