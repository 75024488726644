// src/components/DoctorProfile.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const DoctorProfileChild = () => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [profile, setProfile] = useState(null);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/${user._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.users;
        setProfile(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
    { value: "Dont want to Disclose", label: "Dont want to Disclose" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(profile);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const dropDownChange = (selected, dropdown) => {
    setProfile({
      ...profile,
      [dropdown.name]: selected.value,
    });
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    // Configuration for axios request
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${profile?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...profile,
      },
    };

    // Axios request
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
        // errorLogApi(error, "AddBaptism", "/baptism/add");
      });
  };

  return (
    <div className="w-full max-w-2xl p-3 mx-auto ">
      {/* {JSON.stringify(profile)} */}
      <h2 className="mb-6 text-2xl font-bold">Doctor Profile</h2>
      <form onSubmit={handleProfileUpdate} className="space-y-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={profile?.first_name}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={profile?.last_name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={profile?.email}
              onChange={handleChange}
              disabled={true}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium" htmlFor="mobile">
              Mobile
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={profile?.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="designation"
            >
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={profile?.designation}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Gender
            </label>
            <Select
              name="gender"
              onChange={dropDownChange}
              options={genderOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Gender")}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              styles={style}
              value={
                profile?.gender &&
                genderOptions?.find(
                  (option) => option.value === profile?.gender
                )
              }
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="date_of_birth"
            >
              Date of Birth
            </label>
            <DatePicker
              className="h-[41px] w-full"
              name="date_of_birth"
              onChange={(date, dateString) => {
                setProfile({
                  ...profile,
                  date_of_birth: dateString,
                });
              }}
              disabledDate={(current) => {
                let customDate = dayjs().format("YYYY-MM-DD");
                return current && current > dayjs(customDate, "YYYY-MM-DD");
              }}
              value={
                profile?.date_of_birth &&
                dayjs(profile?.date_of_birth, "YYYY-MM-DD")
              }
              showToday={true}
            />
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={profile?.address}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="date_of_joining"
            >
              Date of Joining
            </label>
            <DatePicker
              className="h-[41px] w-full"
              name="date_of_joining"
              onChange={(date, dateString) => {
                setProfile({
                  ...profile,
                  date_of_joining: dateString,
                });
              }}
              value={
                profile?.date_of_joining &&
                dayjs(profile?.date_of_joining, "YYYY-MM-DD")
              }
              showToday={true}
            />
          </div>
          <div>
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="organization"
            >
              Organization
            </label>
            <input
              type="text"
              id="organization"
              name="organization"
              value={profile?.organization}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <button
            type="submit"
            className="p-3 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Save Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoctorProfileChild;
