import React from "react";

const DocumentDetails = ({ currentRecord }) => {
  return (
    <div className="mb-8 overflow-hidden bg-white rounded-lg shadow-md">
      <div key={currentRecord?.name} className="flex border-b last:border-b-0">
        <div className="w-1/3 p-3 font-semibold bg-gray-100">Name</div>
        <div className="w-2/3 p-3">{currentRecord?.name}</div>
      </div>
      {/* Organization */}
      <div
        key={currentRecord?.organization}
        className="flex border-b last:border-b-0"
      >
        <div className="w-1/3 p-3 font-semibold bg-gray-100">Organization</div>
        <div className="w-2/3 p-3">{currentRecord?.organization}</div>
      </div>
      {/* Applied for */}
      <div
        key={currentRecord?.membership_applied_for}
        className="flex border-b last:border-b-0"
      >
        <div className="w-1/3 p-3 font-semibold bg-gray-100">Applied for</div>
        <div className="w-2/3 p-3">
          {JSON.parse(currentRecord?.membership_applied_for)?.label}
        </div>
      </div>
      {/* Education Qualification */}

      <div
        key={currentRecord?.educational_qualification}
        className="flex border-b last:border-b-0"
      >
        <div className="w-1/3 p-3 font-semibold bg-gray-100">Qualification</div>
        <div className="w-2/3 p-3">
          {JSON.parse(currentRecord?.educational_qualification)?.label}
        </div>
      </div>
      {/* Documents */}
      <div
        key={currentRecord?.document_upload}
        className="flex border-b last:border-b-0"
      >
        <div className="w-1/3 p-3 font-semibold bg-gray-100">Documents</div>
        <div className="w-2/3 p-3">
          <a
            href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${currentRecord.document_upload}`}
            target="_blank"
            rel="noopener noreferrer"
            download
            className="px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-purple-500 rounded-md shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetails;
