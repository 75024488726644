import React, { useState } from "react";

import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PendingRegistrationModal = ({ currentRecord, getDataFromServer }) => {
  const [cRecord, setCRecord] = useState(currentRecord);
  const renderFile = () => {
    if (!cRecord?.transaction_receipt) {
      return <p>No receipt available</p>;
    }

    const fileExtension = cRecord.transaction_receipt
      .split(".")
      .pop()
      .toLowerCase();

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      // It's an image
      return (
        <img
          crossOrigin="anonymous"
          src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${cRecord.transaction_receipt}`}
          alt="Transaction Receipt"
          className="w-full h-auto"
        />
      );
    } else if (fileExtension === "pdf") {
      // It's a PDF
      return (
        <a
          href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${cRecord.transaction_receipt}`}
          download
          target="_blank"
          className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Download PDF
        </a>
      );
    } else {
      return <p>Unsupported file format</p>;
    }
  };

  //Create an approval post
  const handleApprove = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/approve/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        data: { send_approve_email: true },
      },
    };

    axios(config)
      .then(function (response) {
        toast.success("User Approved Successfully.");
        getDataFromServer();
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
      });
  };

  return (
    <div className="max-w-4xl p-6 mx-auto mt-4 bg-white border border-gray-200 rounded-lg shadow-lg">
      <div className="items-center w-full mb-4">
        <button
          className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          onClick={handleApprove}
        >
          Approve
        </button>
      </div>
      {renderFile()}
    </div>
  );
};

export default PendingRegistrationModal;
