import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorised = ({ mesg }) => {
  let navigate = useNavigate();
  const handleClick = () => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="patient-list page-content403">
      {mesg ? (
        <>
          <p>{mesg}</p>
          <p>
            <button
              onClick={handleClick}
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            >
              Click here
            </button>
          </p>
        </>
      ) : (
        <>
          <div>
            <h2>404</h2>
          </div>
          <div>
            <h9>&nbsp;User Authorization required !</h9>
          </div>
          <div>Please contact Support / Admin.</div>
        </>
      )}
    </div>
  );
};

export default Unauthorised;
