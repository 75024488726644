import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const CertificateGenerator = ({ certificate }) => {
  const certificateRef = useRef(null);
  const templateUrl = certificate?.template_background;
  const name = certificate?.name_on_certificate;
  const certificateId = certificate?.certificate_code;

  const generatePDF = () => {
    if (!templateUrl) {
      alert("Please wait for the image to load completely.");
      return;
    }
    const input = certificateRef.current;

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      onclone: (clonedDoc) => {
        // Remove text elements from the cloned document
        clonedDoc.querySelector(".certificate-id").style.display = "none";
        clonedDoc.querySelector(".certificate-name").style.display = "none";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 297; // A4 width in mm
      const imgHeight = 210; // A4 height in mm

      // Add the background image
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Add the certificate ID
      pdf.setFont("helvetica");
      pdf.setFontSize(15);
      pdf.setTextColor(0, 0, 0);
      pdf.text(certificateId, imgWidth / 2, 30, { align: "center" });

      // Add the name (moved slightly upward)
      pdf.setFontSize(24);
      // Adjust this value to move the name up or down
      const nameYPosition = imgHeight / 2 - 10; // Subtracting 10 mm to move it up
      pdf.text(name, imgWidth / 2, nameYPosition, { align: "center" });

      pdf.save(`${certificate?.certificate_code || "certificate"}.pdf`);
    });
  };

  return (
    <div className="flex items-center justify-center p-5 mt-4 border rounded-lg shadow-lg">
      <div className="w-full max-w-[297mm] aspect-[297/210]">
        <div
          ref={certificateRef}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${templateUrl}`}
            alt="Template Background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <div
            className="certificate-id"
            style={{
              position: "absolute",
              top: "15%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 1,
              color: "black",
              textShadow: "1px 1px 2px white",
            }}
          >
            <p style={{ fontSize: "18px" }}>{certificateId}</p>
          </div>
          <div
            className="certificate-name"
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 1,
              color: "black",
              textShadow: "1px 1px 2px white",
            }}
          >
            <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>{name}</h2>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={generatePDF}
            className="p-2 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default CertificateGenerator;
