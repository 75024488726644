import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import TermsAndConditions from "./TermsAndConditions";

const MembershipApplication = ({ getMemberDataFromServer }) => {
  const [globalState] = useAtom(gbState);
  const { user, placeHolderText } = globalState;
  const [file, setFile] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedMembershipType, setSelectedMembershipType] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const qualificationOptions = [
    { value: "bachelors", label: "Bachelor's Degree" },
    { value: "masters", label: "Master's Degree" },
    { value: "doctoral", label: "Doctorate/Doctoral Degree" },
  ];

  const membershipOptions = [
    { value: "LIFETIME", label: "Lifetime Membership" },
    { value: "ASSOCIATE", label: "Associate Membership" },
  ];

  const handleChange = (e) => {
    const { files } = e.target;
    setFile(files[0]);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
  };

  const handleFileUpload = () => {
    if (!agreedToTerms) {
      toast.error("Please agree to the Terms & Conditions.");
      return;
    }

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    if (!selectedQualification) {
      toast.error("Please select an educational qualification.");
      return;
    }

    if (!selectedMembershipType) {
      toast.error("Please select a membership type.");
      return;
    }

    const formData = new FormData();
    formData.append("document_upload", file);
    formData.append(
      "educational_qualification",
      JSON.stringify(selectedQualification)
    );
    formData.append(
      "membership_applied_for",
      JSON.stringify(selectedMembershipType)
    );

    const userToken = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_API_URL}/user/upload/${user?._id}`;

    axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res?.data?.message);
        setIsSubmitted(true);
        getMemberDataFromServer();
        console.log(res?.data);
      })
      .catch((err) => {
        toast.error(
          err.response?.data
            ? JSON.stringify(err.response.data)
            : "An error occurred"
        );
        console.log(err);
      });
  };

  const TermsAndConditionsText = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="p-8 bg-white rounded-lg max-w-2xl max-h-[80vh] overflow-y-auto">
        {/* <h2 className="mb-4 text-2xl font-bold">Terms and Conditions</h2> */}
        <TermsAndConditions />
        <button
          className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          onClick={() => setShowTerms(false)}
        >
          Close
        </button>
      </div>
    </div>
  );

  if (showTerms) {
    return <TermsAndConditionsText />;
  }

  return (
    <div className="max-w-4xl p-6 mx-auto mt-4 bg-white border border-gray-200 rounded-lg shadow-lg">
      {isSubmitted ? (
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800">Thank you!</h2>
          <p className="mt-4 text-gray-600">
            Your document is awaiting verification.
          </p>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-center w-full mt-3">
            <label htmlFor="membership_applied_for">
              Membership Type <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="membership_applied_for"
              onChange={(selected) => setSelectedMembershipType(selected)}
              options={membershipOptions}
              className="w-full h-10 outline-none"
              placeholder={placeHolderText("Select Membership")}
              styles={style}
              value={selectedMembershipType}
              menuPosition={"fixed"}
            />
          </div>
          <div className="flex flex-col justify-center w-full mt-3">
            <label htmlFor="educational_qualification">
              Educational Qualification <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="educational_qualification"
              onChange={(selected) => setSelectedQualification(selected)}
              options={qualificationOptions}
              className="w-full h-10 outline-none"
              placeholder={placeHolderText("Select Qualification")}
              styles={style}
              value={selectedQualification}
              menuPosition={"fixed"}
            />
          </div>
          <div className="flex flex-col justify-center w-full mt-3">
            <label htmlFor="document_upload">Upload supporting documents</label>
            <input
              type="file"
              id="document_upload"
              name="document_upload"
              accept=".pdf,.jpg,.jpeg,.png,.gif"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreedToTerms}
              onChange={() => setAgreedToTerms(!agreedToTerms)}
              className="mr-2"
            />
            <label htmlFor="agreeTerms" className="text-sm">
              I Agree with{" "}
              <span
                className="text-blue-500 cursor-pointer hover:underline"
                onClick={() => setShowTerms(true)}
              >
                Terms & Conditions
              </span>
            </label>
          </div>
          <div className="flex justify-end w-full mt-3 text-center">
            <button
              className={`p-3 text-white rounded ${
                agreedToTerms
                  ? "bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={handleFileUpload}
              disabled={!agreedToTerms}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipApplication;
