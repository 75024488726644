import React, { useState, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ClientCaptcha from "react-client-captcha";

const ForgotPassword = ({ setShowModalForgotPass }) => {
  const [email, setEmail] = useState("");
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [loading, setLoading] = useState(false);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [captchaWidth, setCaptchaWidth] = useState(160);
  let captchaRef = useRef();
  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state.valid !== state.captchaCode) {
      toast.error("Unable to send, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    setLoading(true);

    try {
      const res = await forgotPassword(email);

      if (res.status === 200) {
        setShowModalForgotPass(false);
        toast.success("Password reset link sent to your email");
      } else {
        throw new Error("Failed to send password reset link");
      }
    } catch (error) {
      handleForgotPasswordError(error);
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/fpass`,
        { email }
      );
      return response;
    } catch (error) {
      throw new Error("Network error");
    }
  };

  const handleForgotPasswordError = (error) => {
    if (error.message === "Network error") {
      toast.error("Network error: Please check your internet connection");
    } else if (error.message === "Failed to send password reset link") {
      toast.error("Something went wrong while sending the password reset link");
    } else {
      toast.error("An unexpected error occurred");
    }
  };

  return (
    <div className="w-full overflow-hidden ">
      <div className="px-6 py-4 text-black ">
        <h2 className="text-2xl font-bold">Forgot Password</h2>
        <p className="text-gray-300">Enter your email to reset your password</p>
      </div>
      <form onSubmit={handleSubmit} className="px-8 py-3">
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-bold text-gray-700"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-row mb-3 text-sm font-medium">
          <div className="w-1/3">
            <ClientCaptcha
              //fontFamily={"'Times New Roman', serif"}
              captchaCode={setCode}
              width={captchaWidth}
              className="rounded-md"
            />
          </div>
          <div className="w-2/3">
            <input
              type="text"
              placeholder="Enter Captcha"
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
              onChange={(e) => setState({ ...state, valid: e.target.value })}
              name="valid"
              ref={captchaRef}
              onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="p-3 text-white rounded bg-gradient-to-r bg-navy-400 to-sky-500 hover:bg-navy-200"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Reset Password"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
