import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import UploadEducationalCertificateChild from "./UploadEducationalCertificateChild";

const UploadEducationalCertificate = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <UploadEducationalCertificateChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default UploadEducationalCertificate;
