import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const GenerateCertificate = ({ setShowModal, getDataFromServer }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [templateOptions, setTemplateOptions] = useState();
  const [memberOptions, setMemberOptions] = useState();

  const [formData, setFormData] = useState({
    template_id: "",
    name_on_certificate: "",
    template_background: null,
    active_status: "true",
    user_id: "",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disables the blue border
      boxShadow: "none",
    }),
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/template/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response?.data?.filter(
            (template) => template?.active_status === true
          );
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              value: data?._id,
              label: data?.template_name + " (" + data?.template_code + ")",
            };
          });

          setTemplateOptions(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response?.data?.users?.filter(
            (user) =>
              user?.certificate_status === false &&
              (user?.role === "DOCTOR" || user?.role === "ASSOCIATE")
          );

          const updatedData = oldData.map((data) => {
            return {
              ...data,
              value: data._id,
              label: `${data.first_name} ${data.last_name}`,
            };
          });

          setMemberOptions(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };

    getDataFromServer();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const activeStatusOptions = [
    { value: "0", label: "InActive" },
    { value: "1", label: "Active" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData?.name_on_certificate) {
      return toast.error("Please enter name on certificate");
    }

    if (!formData?.template_id) {
      return toast.error("Please select template");
    }

    if (!formData?.user_id) {
      return toast.error("Please select Member");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/certificate/add/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        toast.success("Certificate Generated Successfully.");
        getDataFromServer();
        setShowModal(false);
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
      });
  };

  return (
    <div className="w-full max-w-md p-5 mx-auto mt-10 ">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="template_id"
          >
            Template ID
          </label>
          <Select
            name="template_id"
            onChange={(selected) =>
              setFormData({
                ...formData,
                template_id: selected?.value,
                template_background: selected?.template_background,
              })
            }
            options={templateOptions}
            placeholder={placeHolderText("Select Template")}
            styles={style}
            value={templateOptions?.find(
              (option) => option.value === formData.template_id
            )}
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium" htmlFor="user_id">
            User ID
          </label>
          <Select
            name="user_id"
            onChange={(selected) =>
              setFormData({
                ...formData,
                user_id: selected?.value,
                name_on_certificate: selected?.label,
              })
            }
            options={memberOptions}
            placeholder={placeHolderText("Select Member")}
            styles={style}
            value={memberOptions?.find(
              (option) => option.value === formData.user_id
            )}
            menuPosition={"fixed"}
          />
        </div>
        <div>
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="name_on_certificate"
          >
            Name on Certificate
          </label>
          <input
            type="text"
            id="name_on_certificate"
            name="name_on_certificate"
            value={formData.name_on_certificate}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div>
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="active_status"
          >
            Active Status
          </label>
          <Select
            name="active_status"
            onChange={dropDownChange}
            options={activeStatusOptions}
            //   className={`w-[${inputWidth}] outline-none h-10`}
            placeholder={placeHolderText("Select Status")}
            styles={style}
            value={
              formData?.active_status &&
              activeStatusOptions?.find(
                (option) => option.value === formData?.active_status
              )
            }
            menuPosition={"fixed"}
          />
        </div>

        <div className="text-end">
          <button
            type="submit"
            className="p-3 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Generate Certificate
          </button>
        </div>
        {/* {JSON.stringify(formData)} */}
      </form>
    </div>
  );
};

export default GenerateCertificate;
