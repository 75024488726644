import React from "react";

const CardBackground = ({ children }) => {
  return (
    <div
      className="flex flex-row p-10 m-2 space-y-0 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-5"
      style={{ wordBreak: "break-word" }}
    >
      {children}
    </div>
  );
};

export default CardBackground;
