import React from "react";

const PendingApplication = () => {
  return (
    <div className="max-w-4xl mx-auto mt-8 overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg">
      <div className="p-8 text-center">
        <h2 className="mb-6 text-3xl font-bold text-gray-800">
          Application Status
        </h2>
        <p className="text-lg text-gray-600">
          Your application is currently pending. Please check back later for
          updates.
        </p>
        <div className="mt-8">
          <div className="inline-block w-16 h-16 border-t-4 border-blue-500 rounded-full animate-spin"></div>
        </div>
      </div>
    </div>
  );
};

export default PendingApplication;
