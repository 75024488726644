import React from "react";

const SubscriptionInformation = () => {
  return (
    <div className="max-w-4xl p-6 mx-auto bg-white border border-gray-200 rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-center text-gray-800">
        Membership Information
      </h2>
      <p className="mb-4 text-lg text-gray-700">
        Membership fees to the following Bank Account
      </p>
      <ul className="mb-4 text-gray-700 list-disc list-inside">
        <li>
          <strong>Membership Fees:</strong> INR 2000/-
          <ul className="ml-4 list-none">
            <li>
              <strong>Status:</strong> LIFETIME
            </li>
          </ul>
        </li>
        <li>
          <strong>Associate Membership Fees:</strong> INR 1000/-
          <ul className="ml-4 list-none">
            <li>
              <strong>Status:</strong> LIFETIME
            </li>
          </ul>
        </li>
      </ul>
      <div className="mb-4 text-gray-700">
        <p>
          <strong>Bank Name:</strong> CANARA BANK
        </p>
        <p>
          <strong>Account Name:</strong> PERITONEAL DIALYSIS
        </p>
        <p>
          <strong>Account Number:</strong> 6007 1010 006563
        </p>
        <p>
          <strong>IFSC CODE:</strong> CNRB0016392
        </p>
        <p>
          <strong>Branch:</strong> Palayamkottai, Tirunelveli, Tamil Nadu –
          627005
        </p>
      </div>
      <p className="text-lg text-gray-700">
        <strong>Please Note:</strong> Once the membership fee has been paid,
        kindly keep the receipt handy during the registration process and upload
        the image in JPG format in the TRANSACTION RECEIPT section given below.
      </p>
    </div>
  );
};

export default SubscriptionInformation;
