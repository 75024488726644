import React from "react";

const ViewTemplate = ({ currentRecord }) => {
  return (
    <div className="w-full max-w-md mx-auto mt-10">
      <div className="w-full mb-4">
        <img
          crossOrigin="anonymous"
          src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/${currentRecord?.template_background}`}
          style={{ height: "400px" }}
          className="w-full p-0 mr-1"
        />
      </div>
      <h2 className="text-2xl font-bold mb-6 text-center">
        {currentRecord.template_name}
      </h2>
      <div className="mb-4">
        <strong>Template Code:</strong> {currentRecord.template_code}
      </div>
      <div className="mb-4">
        <strong>Active Status:</strong>{" "}
        {currentRecord.active_status ? "Active" : "Inactive"}
      </div>
    </div>
  );
};

export default ViewTemplate;
