import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";

import MembershipApplication from "./MembershipApplication";

const ApplyForMembership = ({ getMemberDataFromServer }) => {
  let navigate = useNavigate();
  const [showLifetimeModal, setShowLifetimeModal] = useState(false);
  const [showAssociateModal, setShowAssociateModal] = useState(false);

  const toggleLifetimeModal = () => {
    setShowLifetimeModal(!showLifetimeModal);
  };

  const toggleAssociateModal = () => {
    setShowAssociateModal(!showAssociateModal);
  };

  return (
    <div className="max-w-4xl p-5 mx-auto mt-4 border rounded-lg shadow-lg md:mt-0">
      {showLifetimeModal && (
        <Modal onClose={toggleLifetimeModal}>
          <h2 className="mb-4 text-xl font-bold">Membership Application</h2>
          <MembershipApplication
            getMemberDataFromServer={getMemberDataFromServer}
          />
        </Modal>
      )}
      <div className="flex flex-col justify-center w-full md:flex-row">
        <div className="flex items-center justify-center w-full mt-3 text-center md:mt-0 md:w-1/3">
          <button
            className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
            onClick={() => setShowLifetimeModal(true)}
          >
            Apply for Membership
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyForMembership;
