import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import UserView from "./UserView";
import UserRegistration from "./UserRegistration";
import UpdateUserRegistration from "./UpdateUserRegistration";
import { render } from "@testing-library/react";
import PendingRegistrationModal from "./PendingRegistrationModal";

const AdminDashboardChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };
  const toggleUpdateUserModal = () => {
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const toggleApprovalModal = () => {
    setShowApprovalModal(!showApprovalModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    if (user?.email === row.email) {
      // if user is trying to delete himself
      return toast.error("Cannot Delete Logged In user!");
    }

    let userToken = localStorage.getItem("token");

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/delete/${row._id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "UsersListChild", "/users");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response?.data?.users;
        const updatedData = oldData?.map((data) => {
          return {
            ...data,
            id: data?._id,
            name: `${data?.member_title} ${data?.first_name} ${data?.last_name}`,
            email: data?.email,
            role: data?.role,
            status: data?.user_type,
            subscription_type: data?.subscription_type,
            account_status: data?.account_status,
            verification_status:
              data?.subscription_type === "Free" && data?.transaction_receipt
                ? "Pending"
                : data?.transaction_receipt
                ? "Approved"
                : "N/A",
          };
        });
        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "verification_status",
      headerName: "Verification",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => {
        if (params.row.verification_status === "Approved") {
          return (
            <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded ">
              {params.row.verification_status}
            </span>
          );
        }
        if (params.row.verification_status === "Pending") {
          return (
            <span
              onClick={() => handleApprovalButton(params.row)}
              className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded shadow-lg cursor-pointer"
            >
              {params.row.verification_status}
            </span>
          );
        }
        if (params.row.verification_status === "N/A") {
          return (
            <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-gray-600 rounded ">
              {params.row.verification_status}
            </span>
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <span
              className={`mr-2 w-2.5 h-2.5 rounded-full ${
                params.row.account_status ? "bg-green-500" : "bg-red-500"
              }`}
            ></span>
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
    },
    {
      field: "user_type",
      headerName: "Role",
      width: 140,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 140,
    },
    {
      field: "subscription_type",
      headerName: "Subscription",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return params.row.subscription_type === "Free" ? (
          <span className="inline-block w-full px-2 py-1 text-xs text-center text-gray-800 bg-red-300 rounded ">
            {params.row.subscription_type}
          </span>
        ) : (
          <span className="inline-block w-full px-2 py-1 text-xs text-center text-gray-800 bg-green-300 rounded ">
            {params.row.subscription_type}
          </span>
        );
      },
    },

    // {
    //   field: "account_status",
    //   headerName: "Status",
    //   sortable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   width: 140,
    //   renderCell: (params) => {
    //     return params.row.account_status ? (
    //       <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded ">
    //         ACTIVE
    //       </span>
    //     ) : (
    //       <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded ">
    //         DISABLED
    //       </span>
    //     );
    //   },
    // },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateUserModal(true);
  };

  const handleApprovalButton = (row) => {
    setCurrentRecord(row);
    setShowApprovalModal(true);
  };

  return (
    <div className="w-full mt-4" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-end w-full">
        <button
          className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          onClick={toggleAddUserModal}
        >
          Add User
        </button>
      </div>
      <h2>All Users</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">User Detail</h2>
            <UserView user={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddUserModal && (
          <Modal onClose={toggleAddUserModal}>
            <h2 className="mb-4 text-xl font-bold">Add User</h2>
            <UserRegistration
              setShowModal={setShowAddUserModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateUserModal && (
          <Modal onClose={toggleUpdateUserModal}>
            <h2 className="mb-4 text-xl font-bold">Update User</h2>
            <UpdateUserRegistration
              setShowModal={setShowUpdateUserModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showApprovalModal && (
          <Modal onClose={toggleApprovalModal}>
            <h2 className="mb-4 text-xl font-bold">Update User</h2>
            <PendingRegistrationModal
              setShowModal={setShowUpdateUserModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>

      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default AdminDashboardChild;
