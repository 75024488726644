// src/components/DoctorRegistration.jsx
import React, { useState, useRef, useEffect } from "react";
import ClientCaptcha from "react-client-captcha";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import useFetch from "../../components/shared/useFetch";

const UserRegistration = ({ setShowModal, getDataFromServer }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  const [captchaWidth, setCaptchaWidth] = useState(250);
  const [roleOptions, setRoleOptions] = useState();
  let captchaRef = useRef();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    hospital_name: null,
    organization: null,
    password: null,
    confirm_password: null,
    account_status: true,
    role: null,
    user_type: null,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleResize = () => setCaptchaWidth(mediaQuery.matches ? 250 : 150);
    mediaQuery.addListener(handleResize);
    handleResize(); // Set the initial value
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const role_url = `${process.env.REACT_APP_API_URL}/roles/`;
  const { data, loading, error } = useFetch(role_url);

  useEffect(() => {
    if (data) {
      setRoleOptions(
        data?.roles?.map((role) => ({
          value: role.access_level,
          label: role.role_name,
        }))
      );
      // console.log(data);
    }
  }, [data]);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const paymentStatusOptions = [
    { value: "0", label: "Not Paid" },
    { value: "1", label: "Paid" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();
    //Check Captcha
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (formData.password !== formData.confirm_password)
      return toast.error(`Passwords not Matching! ${formData.password}`);

    // console.log(formData);
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/admin/register`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("User Created Successfully.");
        getDataFromServer();
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        //errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div className="w-full">
      <form className="space-y-4">
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={formData.last_name && formData.last_name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="mobile">
              Mobile
            </label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              value={formData.mobile && formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="organization"
            >
              Organization
            </label>
            <input
              type="text"
              name="organization"
              id="organization"
              value={formData.organization && formData.organization}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div></div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email && formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Member roles
            </label>
            <Select
              name="role"
              onChange={(selected, dropdown) =>
                setFormData({
                  ...formData,
                  role: selected.value,
                  user_type: selected.label,
                })
              }
              options={roleOptions}
              placeholder={placeHolderText("Select Status")}
              styles={style}
              value={
                formData?.role &&
                roleOptions?.find((option) => option.value === formData?.role)
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        &nbsp;
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <ClientCaptcha
              captchaCode={setCode}
              width={captchaWidth}
              className="rounded-md"
            />
          </div>
          <div className="w-full mt-4 md:w-1/2 md:mt-0">
            <input
              type="text"
              placeholder="Enter Captcha"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              onChange={(e) => setState({ ...state, valid: e.target.value })}
              name="valid"
              ref={captchaRef}
              onKeyPress={(e) =>
                e.key === "Enter" && handleRegistrationSubmit(e)
              }
              autoComplete="off"
            />
          </div>
        </div>
        <div className="text-end">
          <button
            onClick={(e) => handleRegistrationSubmit(e)}
            className="p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserRegistration;
