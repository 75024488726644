import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import jwt_decode from "jwt-decode";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const PrivateRoutes = (props) => {
  let navigate = useNavigate();
  const [{ errorLogApi, user }] = useAtom(gbState);

  let usrDetails = jwt_decode(localStorage.getItem("token"));

  // useEffect(() => {
  //   setloggedInUserDetails(jwt_decode(localStorage.getItem("token")));
  // }, []);

  let currentUserRole = null;

  if (usrDetails !== null) {
    currentUserRole = usrDetails?.role;
    // console.log("when user details exists");
    //  console.log(usrDetails);
  } else {
    //  console.log("No user details exists");
    navigate("/login", { replace: true });
  }

  if (!currentUserRole) {
    //  console.log("Trying to redirect");
    localStorage.removeItem("token");
  }

  let auth = { token: localStorage.getItem("token") };

  if (props.roleRequired && currentUserRole) {
    return auth.token ? (
      props.roleRequired.includes(currentUserRole) ? (
        <Outlet />
      ) : (
        <Navigate to="/error403" />
      )
    ) : (
      <Navigate to="/login" />
    );
  } else {
    return <Navigate to="/login" />;
  }
};
PrivateRoutes.defaultProps = {
  roleRequired: ["ADMIN", "LIFETIME", "NOT_A_MEMBER", "ASSOCIATE"],
};

export default PrivateRoutes;
