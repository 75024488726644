import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { IconButton, InputAdornment, TextField, Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const ChangePasswordChild = () => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [profile, setProfile] = useState(null);
  const [newPassword, setNewPassword] = useState({
    password: null,
    confirm_password: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/${user._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.users;
        setProfile(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPassword({
      ...newPassword,
      [name]: value,
    });
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    if (newPassword?.password !== newPassword?.confirm_password) {
      return toast.error("New password is not same as current password");
    }

    confirmAlert({
      title: "",
      message: "Are you sure to update this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => changePassword(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const changePassword = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/cpass/${profile?._id}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: {
          password: newPassword.password,
        },
      };

      axios(config)
        .then(function (response) {
          setNewPassword({ password: "", confirm_password: "" });
          passwordRef.current.value = "";
          confirmPasswordRef.current.value = "";
          toast.success(response?.data?.message);
        })
        .catch(function (error) {
          toast.error(
            error.response?.data
              ? JSON.stringify(error.response.data)
              : "An error occurred"
          );
        });
    };
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-3">
      <h2 className="text-2xl font-bold mb-6">Change Password</h2>
      <form onSubmit={handleProfileUpdate} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="relative">
            <TextField
              label="New Password"
              type={showPassword ? "text" : "password"}
              name="password"
              inputRef={passwordRef}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="relative">
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              inputRef={confirmPasswordRef}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button type="submit" variant="contained" color="primary">
            Save Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordChild;
