import "./Page403.css";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const Page403 = () => {
  return (
    <MainTemplate>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <SideBar /> */}
        <div className="error-403 page-content403">
          <div>
            <h2>403</h2>
          </div>
          <div>
            <h9>&nbsp;You do not have access to the resource.</h9>
          </div>
          <div>Please check your Permission Level or contact admin.</div>
        </div>
      </div>
    </MainTemplate>
  );
};

export default Page403;
