// src/components/TopMenu.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../images/no-img-9.jpg";
import logo_image from "../../images/pdsi-logo-transparent-2x.png";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const TopMenu = () => {
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [isPagesDropdownOpen, setIsPagesDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setUserStatus({ ...userStatus, user: null });
    navigate("/login", { replace: true });
  };

  const handleHomeClick = () => {
    if (user?.role === "ADMIN") {
      navigate("/dashboard-admin");
    } else {
      navigate("/dashboard-member");
    }
  };

  return (
    <nav className="relative flex items-center justify-between p-5 m-5 bg-white rounded-full shadow-md">
      <div className="text-2xl font-bold">
        <img src={logo_image} alt="logo" className="w-40 ml-2" />
      </div>
      <div className="flex items-center md:hidden">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
      <div className="hidden md:flex md:flex-row md:items-center md:space-x-6">
        <a
          onClick={handleHomeClick}
          className="pl-1 pr-1 text-gray-700 cursor-pointer hover:bg-gray-100"
        >
          Home
        </a>
        {/* <a
          onClick={() => navigate("/certificates")}
          className="pl-1 pr-1 text-gray-700 cursor-pointer hover:bg-gray-100"
        >
          Manage Certificates
        </a> */}
        {user?.role === "ADMIN" && (
          <div className="relative">
            <button
              className="flex items-center space-x-2 text-gray-700"
              onClick={() => setIsPagesDropdownOpen(!isPagesDropdownOpen)}
            >
              <span> Admin Panel</span>
              <svg
                className={`w-4 h-4 transition-transform ${
                  isPagesDropdownOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {isPagesDropdownOpen && (
              <div
                onMouseLeave={() => setIsPagesDropdownOpen(false)}
                className="absolute left-0 w-48 mt-2 bg-white border border-gray-200 rounded shadow-lg"
              >
                {/* <a
                  onClick={() => navigate("/applications")}
                  className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                >
                  Current Applications
                </a> */}
                <a
                  onClick={() => navigate("/templates")}
                  className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                >
                  Manage Templates
                </a>
                <a
                  onClick={() => navigate("/certificates")}
                  className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                >
                  Manage Certificates
                </a>
                <a
                  onClick={() => navigate("/roles")}
                  className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                >
                  Manage Roles
                </a>
              </div>
            )}
          </div>
        )}
        {/* <a
          onClick={() => navigate("/account")}
          className="pl-1 pr-1 text-gray-700 cursor-pointer hover:bg-gray-100"
        >
          Account
        </a> */}
        {/* <a href="#" className="text-gray-700">
          Blocks
        </a> */}
        <a
          onClick={() => navigate("/documents")}
          className="pl-1 pr-1 text-gray-700 cursor-pointer hover:bg-gray-100"
        >
          Docs
        </a>
        <div className="relative">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
          >
            <img
              src={loginImage}
              alt="User Avatar"
              className="w-8 h-8 rounded-full cursor-pointer"
            />
            <div className="">
              <div className="ml-1 text-xs text-gray-700">{user?.name}</div>
              <div className="text-gray-400 text-[0.5rem] ml-1">
                {user?.user_type}
              </div>
            </div>
          </div>

          {isAccountDropdownOpen && (
            <div
              onMouseLeave={() => setIsAccountDropdownOpen(false)}
              className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded shadow-lg"
            >
              <a
                onClick={() => navigate("/profile")}
                className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
              >
                Profile
              </a>
              <a
                onClick={() => navigate("/cpass")}
                className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
              >
                Change Password
              </a>
              <span
                onClick={handleLogout}
                className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
              >
                Logout
              </span>
            </div>
          )}
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 z-50 flex flex-col p-5 bg-white md:hidden">
          <div className="flex justify-end">
            <button onClick={() => setIsMenuOpen(false)}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="mt-4 space-y-4">
            <a
              onClick={handleHomeClick}
              className="block text-gray-700 cursor-pointer"
            >
              Home
            </a>
            {/* <a
              onClick={() => navigate("/certificates")}
              className="block text-gray-700 cursor-pointer"
            >
              Manage Certificates
            </a> */}
            {user?.role === "ADMIN" && (
              <div className="relative">
                <button
                  className="flex items-center w-full space-x-2 text-left text-gray-700"
                  onClick={() => setIsPagesDropdownOpen(!isPagesDropdownOpen)}
                >
                  <span> Admin Panel</span>
                  <svg
                    className={`w-4 h-4 transition-transform ${
                      isPagesDropdownOpen ? "transform rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                {isPagesDropdownOpen && (
                  <div className="w-full mt-2 bg-white border border-gray-200 rounded shadow-lg">
                    <a
                      onClick={() => navigate("/templates")}
                      className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    >
                      Manage Templates
                    </a>
                    <a
                      onClick={() => navigate("/certificates")}
                      className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    >
                      Manage Certificates
                    </a>
                    <a
                      onClick={() => navigate("/roles")}
                      className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    >
                      Manage Roles
                    </a>
                  </div>
                )}
              </div>
            )}
            {/* <a
              onClick={() => navigate("/account")}
              className="block text-gray-700"
            >
              Account
            </a> */}
            {/* <a href="#" className="block text-gray-700">
              Blocks
            </a> */}
            <a
              onClick={() => navigate("/documents")}
              className="block text-gray-700 cursor-pointer"
            >
              Docs
            </a>
            <div className="relative">
              <div
                className="flex flex-row items-center cursor-pointer"
                onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
              >
                <img
                  src={loginImage}
                  alt="User Avatar"
                  className="w-8 h-8 rounded-full cursor-pointer"
                />
                <div className="">
                  <div className="ml-1 text-xs text-gray-700">{user?.name}</div>
                  <div className="text-gray-400 text-[0.5rem] ml-1">
                    {user?.user_type}
                  </div>
                </div>
              </div>

              {isAccountDropdownOpen && (
                <div className="absolute right-0 w-full mt-2 bg-white border border-gray-200 rounded shadow-lg">
                  <a
                    onClick={() => navigate("/profile")}
                    className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  >
                    Profile
                  </a>
                  <a
                    onClick={() => navigate("/cpass")}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Change Password
                  </a>
                  <span
                    onClick={handleLogout}
                    className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  >
                    Logout
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default TopMenu;
