import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import CurrentApplicationChild from "./CurrentApplicationChild";

const CurrentApplications = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <CurrentApplicationChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default CurrentApplications;
