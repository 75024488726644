import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const UpdateTemplate = ({ currentRecord, getDataFromServer }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    ...currentRecord,
    active_status: currentRecord.active_status ? "1" : "0",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disables the blue border
      boxShadow: "none",
    }),
  };

  const activeStatusOptions = [
    { value: "0", label: "Inactive" },
    { value: "1", label: "Active" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      template_background: e.target.files[0],
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // try {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/template/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        toast.success("Template Updated Successfully.");
        getDataFromServer();
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
      });
  };

  return (
    <div className="w-full max-w-md mx-auto mt-10">
      <form className="space-y-4">
        <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="template_name"
          >
            Template Name
          </label>
          <input
            type="text"
            id="template_name"
            name="template_name"
            value={formData.template_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="active_status"
          >
            Active Status
          </label>
          <Select
            name="active_status"
            onChange={(selected) =>
              dropDownChange(selected, { name: "active_status" })
            }
            options={activeStatusOptions}
            placeholder={placeHolderText("Select Status")}
            styles={style}
            value={activeStatusOptions.find(
              (option) => option.value === formData.active_status
            )}
            menuPosition={"fixed"}
          />
        </div>
        <div className="text-end">
          <button
            onClick={handleSubmit}
            className="p-3 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Update Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateTemplate;
