import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import UserView from "./UserView";
import GetPaidSubscription from "./GetPaidSubscription";
import ApplyForMembership from "./ApplyForMembership";
import GenerateCertificateMember from "./GenerateCertificateMember";
import DownloadCertificate from "./DownloadCertificate";
import UploadEducationalInformation from "./UploadEducationalInformation";
import PendingApplication from "./PendingApplication";
import UPIQRCode from "./UPIQRCode";
import PaymentProofUpload from "../Subscription/PaymentProofUpload";
import RejectedApplication from "./RejectedApplication";
import CertificateGenerator from "./CertificateGenerator";

const MemberDashboardChild = () => {
  let navigate = useNavigate();
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);

  const [member, setMember] = useState(null);
  const [certificate, setCertificate] = useState();

  // Function to refetch the member
  const getMemberDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/user/${user?._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response?.data?.users;
        setMember(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err.response.data.message);
      });
  };

  useEffect(() => {
    getMemberDataFromServer();
  }, []);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/certificate/user/${user?._id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response?.data;
          console.log(oldData);
          setCertificate(oldData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };

    getDataFromServer();
  }, [member]);

  return (
    <div className="w-full">
      <div className="md:p-5">
        <UserView user={member} />
      </div>
      {member?.subscription_type !== "Paid" && <UploadEducationalInformation />}
      {member?.subscription_type !== "Paid" &&
        !member?.membership_applied_for && (
          <ApplyForMembership
            getMemberDataFromServer={getMemberDataFromServer}
          />
        )}
      {member?.subscription_type !== "Paid" &&
        member?.membership_applied_for &&
        !member?.document_status && <PendingApplication />}
      {member?.subscription_type !== "Paid" &&
        member?.document_status === "Rejected" && <RejectedApplication />}

      {member?.payment_status === "Pending" &&
        member?.document_status === "Approved" &&
        member?.upi_number &&
        member?.amount &&
        !member?.transaction_receipt && (
          <UPIQRCode upiId={member?.upi_number} amount={member?.amount} />
        )}

      {member?.payment_status === "Pending" &&
        member?.document_status === "Approved" &&
        member?.upi_number &&
        member?.amount &&
        !member?.transaction_receipt && (
          <PaymentProofUpload
            getMemberDataFromServer={getMemberDataFromServer}
          />
        )}

      {member?.subscription_type !== "Paid" && member?.transaction_receipt && (
        <PendingApplication />
      )}

      {member?.subscription_type === "Paid" && !member?.certificate_status && (
        <GenerateCertificateMember
          member={member}
          getMemberDataFromServer={getMemberDataFromServer}
        />
      )}
      {/* {member?.certificate_status &&
        (certificate ? (
          <DownloadCertificate certificate={certificate} />
        ) : (
          "Loading..."
        ))} */}

      {member?.certificate_status &&
        (certificate ? (
          <CertificateGenerator certificate={certificate} />
        ) : (
          "Loading..."
        ))}
    </div>
  );
};

export default MemberDashboardChild;
