import React from "react";
import bankLogo from "../../images/canara-bank-logo.png";
import bhimlogoimage from "../../images/bhim-upi-logo.png";
import { QRCodeSVG } from "qrcode.react";

const UPIQRCode = ({ upiId, amount }) => {
  const upiString = `upi://pay?pa=${upiId}&am=${amount}&cu=INR`;

  return (
    <div className="max-w-4xl mx-auto mt-8 overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg">
      <div className="p-6 bg-blue-50">
        <h2 className="mb-4 text-3xl font-bold text-center text-blue-800">
          Payment Options
        </h2>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col items-center p-6 bg-white md:w-1/2">
          <h3 className="mb-4 text-2xl font-semibold text-blue-600">
            Scan to Pay
          </h3>
          <div className="grid p-4 bg-white rounded-lg shadow-md place-items-center">
            <QRCodeSVG
              value={upiString}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
              level="L"
              includeMargin={false}
            />
            <br />
            <img src={bhimlogoimage} width="200px" />
          </div>
          <div className="mt-4 text-center">
            <p className="text-gray-700">
              UPI ID: <span className="font-medium">{upiId}</span>
            </p>
            <p className="mt-2 text-2xl font-bold text-green-600">
              Amount: ₹{amount}
            </p>
          </div>
        </div>
        <div className="p-6 md:w-1/2 bg-gray-50">
          <h3 className="mb-4 text-2xl font-semibold text-blue-600">
            Bank Transfer Details
          </h3>
          <div className="space-y-3 text-gray-700">
            <p>
              <span className="font-semibold">Bank Name:</span> CANARA BANK
            </p>
            <p>
              <span className="font-semibold">Account Name:</span> PERITONEAL
              DIALYSIS
            </p>
            <p>
              <span className="font-semibold">Account Number:</span> 6007 1010
              006563
            </p>
            <p>
              <span className="font-semibold">IFSC CODE:</span> CNRB0016392
            </p>
            <p>
              <span className="font-semibold">Branch:</span> Palayamkottai,
              Tirunelveli, Tamil Nadu – 627005
            </p>
            <div className="grid w-full p-10 place-items-center">
              <img src={bankLogo} width="300px" alt="Bank Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UPIQRCode;
