import "./Page404.css";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const Page404 = () => {
  return (
    <MainTemplate>
      <CardBackground>
        {/* <SideBar /> */}
        <div className="patient-list page-content403">
          <div>
            <h2>404</h2>
          </div>
          <div>
            <h9>&nbsp;Page not found !</h9>
          </div>
          <div>Please contact Support / Admin.</div>
        </div>
      </CardBackground>
    </MainTemplate>
  );
};

export default Page404;
