import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import DocumentDetails from "./DocumentDetails";

const DocumentApproval = ({
  getDataFromServer,
  setShowModal,
  currentRecord,
}) => {
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    email: currentRecord?.email,
    organization: currentRecord?.organization,
    account_status: currentRecord?.account_status,
    document_status: currentRecord?.document_status
      ? currentRecord?.document_status
      : null,
    upi_number: currentRecord?.upi_number
      ? currentRecord?.upi_number
      : process.env.REACT_APP_UPI_NUMBER,
    amount: currentRecord?.amount
      ? currentRecord?.amount
      : JSON.parse(currentRecord?.membership_applied_for)?.value === "LIFETIME"
      ? process.env.REACT_APP_LIFETIME
      : process.env.REACT_APP_ASSOCIATE,
    payment_status: "Pending",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const documentStatusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUpdateDocumentStatus = () => {
    // Check if the document status is not 'Rejected' or 'Approved'
    if (
      formData.document_status !== "Rejected" &&
      formData.document_status !== "Approved"
    ) {
      return toast.error(`Select Document Status: ${formData.document_status}`);
    }

    let acc_status = false;
    if (formData.document_status === "Approved") {
      acc_status = true;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
        account_status: acc_status,
        send_approval_email: true,
      },
    };

    axios(config)
      .then((response) => {
        toast.success("Updated Successfully.");
        getDataFromServer();
        setShowModal(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "An error occurred.");
        errorLogApi(
          error,
          "handleUpdateDocumentStatus",
          `/user/update/${currentRecord?._id}`
        );
      });
  };

  const clearDocumentStatus = () => {
    if (
      currentRecord?.role ===
        JSON.parse(currentRecord?.membership_applied_for)?.value &&
      currentRecord?.subscription_type === "Paid"
    ) {
      return toast.error("Already an Approved Member!");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, reset_document: true },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Cleared Successfully.");
        getDataFromServer();
        setShowModal(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateRoleChild", "/roles/add");
        // console.log(error);
      });
  };

  return (
    <div className="w-full">
      {/* name */}
      <DocumentDetails currentRecord={currentRecord} />
      {/* Form */}
      <div className="mb-8 overflow-hidden bg-white rounded-lg shadow-md">
        {/* Document Status */}
        <div className="flex border-b last:border-b-0">
          <div className="w-1/3 p-3 font-semibold bg-gray-100">
            Document Status
          </div>
          <div className="w-2/3 p-3">
            <Select
              name="document_status"
              onChange={dropDownChange}
              options={documentStatusOptions}
              //   className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Document Status")}
              //   styles={style}
              value={
                formData?.document_status &&
                documentStatusOptions?.find(
                  (option) => option.value === formData?.document_status
                )
              }
              menuPosition={"fixed"}
            />
          </div>
        </div>

        {/* UPI Number */}

        <div className="flex border-b last:border-b-0">
          <div className="w-1/3 p-3 font-semibold bg-gray-100">UPI Number</div>
          <div className="w-2/3 p-3">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="upi_number"
              type="text"
              required
              placeholder="Enter UPI Number"
              autoComplete="new-password"
              disabled={true}
              onChange={handleChange}
              value={formData?.upi_number}
            />
          </div>
        </div>
        {/* Amount */}
        <div className="flex border-b last:border-b-0">
          <div className="w-1/3 p-3 font-semibold bg-gray-100">Amount</div>
          <div className="w-2/3 p-3">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="amount"
              type="Number"
              required
              placeholder="Enter Amount"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData?.amount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full mt-4">
        <button
          className="px-4 py-2 text-white bg-orange-600 rounded hover:bg-orange-900"
          onClick={clearDocumentStatus}
        >
          <i className="mdi mdi-lock-reset"></i>&nbsp; Clear Document Status
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={handleUpdateDocumentStatus}
        >
          Update Status
        </button>
      </div>
    </div>
  );
};

export default DocumentApproval;
